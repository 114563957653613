import React, {Fragment} from "react";
import Home from "../screens/Home";
import TicTacToe from "../screens/Games/TicTacToe";
import Header from "../component/Header";

interface RouteType {
    path: string;
    component: React.ReactElement;
    name: string;
    protected: boolean;
}

const routes: RouteType[] = [{
    path: "/",
    component: <Fragment>
        <Header />
        <Home />
    </Fragment>,
    name: "Home",
    protected: false,
}, {
    path: "/games/tic-tac-toe",
    component: <Fragment>
        <Header />
        <TicTacToe />
    </Fragment>,
    name: "Tic Tac Toe",
    protected: false,
}];

export default routes;
