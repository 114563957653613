import React, {useState, useEffect} from "react";
import LetterX from "../../../assets/games/tic-tac-toe/letter-x.png";
import LetterO from "../../../assets/games/tic-tac-toe/letter-o.png";
import Tie from "../../../assets/games/tic-tac-toe/tie.png";
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react';
import { MdOutlineSportsScore } from "react-icons/md";

enum Item {
    "circle" = "circle",
    "cross" = "cross",
    "empty" = ""
}

const defaultItemsState = Array(9).fill(Item.empty);

const TicTacToe = () => {
    const winningCombo = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];

    const [currentItem, setCurrentItem] = useState<Item>(Item.cross);
    const [items, setItems] = useState<Item[]>([...defaultItemsState]);
    const [winner, setWinner] = useState<Item>(Item.empty);
    const [open, setOpen] = useState(false);
    const [score, setScore] = useState({
        cross: 0,
        circle: 0,
        tie: 0
    });

    useEffect(() => {
        document.title = `Tic Tac Toe | Byte Play`;
    }, []);
    useEffect(() => {
        let winner = Item.empty;
        winningCombo.forEach(combo => {
            const circle = combo.every((index) => items[index] === Item.circle);
            const cross = combo.every((index) => items[index] === Item.cross);

            if (circle) {
                winner = Item.circle;
                setScore({
                    ...score,
                    circle: score.circle + 1
                });
            } else if (cross) {
                winner = Item.cross;
                setScore({
                    ...score,
                    cross: score.cross + 1,
                });
            }
        });

        setWinner(winner);
        if (winner || items.every(item => item !== Item.empty)) {
            if(items.every(item => item !== Item.empty) && winner === Item.empty) {
                setScore({
                    ...score,
                    tie: score.tie + 1,
                });
            }

            setOpen(true);
        }
    }, [items]);

    const handleItemClick = (index: number) => {
        const itemsClone = [...items];

        if (itemsClone[index] !== Item.empty || winner) {
            return false;
        }

        itemsClone[index] = currentItem;
        setItems(itemsClone);

        if (currentItem === Item.circle) {
            setCurrentItem(Item.cross);
        } else {
            setCurrentItem(Item.circle);
        }
    }

    return <div className="flex flex-col items-center justify-center h-screen bg-background">
        <div className={`border-b-2 pb-5`}>
            <h1 className={`text-5xl font-bold`}><span className={`text-blue-800`}>Tic</span>-<span
                className={`text-emerald-800`}>Tac</span>-<span className={`text-red-800`}>Toe</span></h1>
        </div>
        <div className={`text-center pb-5 pt-5`}>
            {!winner && !items.every(item => item !== Item.empty) && <p className={`flex items-center`}>
                Current Player is: <img src={currentItem === Item.circle ? LetterO : LetterX} alt={`Circle`} width={24} className={`ml-2`}/>
            </p>}
            {winner && <p className={`flex items-center`}>
                The winner is: <img src={winner === Item.circle ? LetterO : LetterX} alt={`Circle`} width={24} className={`ml-2`}/>
            </p>}
            {!winner && items.every(item => item !== Item.empty) && <p className={`flex items-center`}>
                It's a <img src={Tie} alt={`Tie`} width={24} className={`ml-2`}/>! Try again.
            </p>}
        </div>
        <div className="grid grid-cols-3 gap-3">
        {items.map((item, index) => {
                return <button key={index}
                               className={`w-24 h-24 flex items-center justify-center text-4xl font-bold rounded-md transition-colors ${
                                   item === Item.cross ? "bg-red-100 text-primary-foreground" : item === Item.circle ? "bg-blue-100 text-secondary-foreground" : "bg-gray-200 hover:bg-muted-foreground"
                               }`} onClick={() => handleItemClick(index)}>
                    {item === Item.cross && <img src={LetterX} alt={`Cross`} width={64}/>}
                    {item === Item.circle && <img src={LetterO} alt={`Circle`} width={64}/>}
                </button>
            })}
        </div>
        <div className={`text-center pt-5 pb-5 border-b-2`}>
            <button
                type="button"
                onClick={() => {
                    setOpen(false);
                    setWinner(Item.empty);
                    setItems(defaultItemsState);
                    setCurrentItem(Item.cross);
                }}
                className="inline-flex w-full justify-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
            >
                Restart
            </button>
        </div>
        <div className={`text-center pt-5`}>
            <h3 className={`flex items-center justify-center text-center text-2xl`}>Score <MdOutlineSportsScore className={`ml-2`}/></h3>
            <div className={`pt-5 flex items-center justify-between`}>
                <div>
                    <img src={LetterX} alt={`Cross`} width={24}/>
                    <div className={`pt-4`}>
                        {score.cross}
                    </div>
                </div>
                <div className={`ml-5`}>
                    <img src={LetterO} alt={`Circle`} width={24}/>
                    <div className={`pt-4`}>
                        {score.circle}
                    </div>
                </div>
                <div className={`ml-5`}>
                    <img src={Tie} alt={`Circle`} width={24}/>
                    <div className={`pt-4`}>
                        {score.tie}
                    </div>
                </div>
            </div>
        </div>
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="bg-white px-4 pb-16 pt-16 text-center mx-auto">
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 text-center flex items-center justify-center">
                                    {!winner && <span className={`text-3xl flex items-center`}>It was a<img src={Tie} alt={`Tie`} width={32} className={`ml-2`}/>!</span>}
                                    {winner && <span className={`text-3xl flex items-center`}>The winner is
                                        <img src={winner === Item.circle ? LetterO : LetterX} alt={`Circle`} width={24} className={`ml-2`}/>
                                    </span>}
                                </DialogTitle>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                onClick={() => {
                                    setOpen(false);
                                    setWinner(Item.empty);
                                    setItems(defaultItemsState);
                                    setCurrentItem(Item.cross);
                                }}
                                className="inline-flex w-full justify-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                            >
                                Restart
                            </button>
                            <button
                                type="button"
                                data-autofocus={true}
                                onClick={() => setOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    </div>
};

export default TicTacToe;
