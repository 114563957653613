import Header from "../../component/Header";

const Home = () => {
    return (
        <div className="bg-white">

        </div>
    )
};

export default Home;
