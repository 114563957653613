import React from 'react'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import routes from "./config/routes";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    {routes.map((route, index) => {
                        return <Route
                            key={index}
                            path={route.path}
                            element={route.component}
                        />
                    })}
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
